import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";



import './App.css';
import logo from './assets/images/povtorno-20-cigari.png';
import nextbutton from './assets/images/next.png';
import done from './assets/images/check.png';
import recenica from './assets/images/recenica.png';
import arrow from './assets/images/arrow.png';


import row111 from './assets/images/row-1-1-1.png';
import row112 from './assets/images/row1-1-2.png';

import row121 from './assets/images/row-1-2-1.png';
import row122 from './assets/images/row-1-2-2.png';



import row211 from './assets/images/row-2-1-1.png';
import row212 from './assets/images/row-2-1-2.png';

import row221 from './assets/images/row-2-2-1.png';
import row222 from './assets/images/row-2-2-2.png';

import rodeobox from './assets/images/rodeo-box.png';

import rodeosoft from './assets/images/rodeo-soft.png';

import westtutun from './assets/images/west-tutun.png';




export default function App() {
  return (
    <Router>
      <div class="App">   
        <div className="rotator"></div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/last">
            <Last />
          </Route>
          <Route path="/first">
            <First />
          </Route>
          <Route path="/second">
            <Second />
          </Route>
          <Route path="/third">
            <Third />
          </Route>
          <Route path="/fourth">
            <Fourth />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function Home() {
  return (
    <div className="App-logo--container">
      <img src={logo} alt="Logo" class="App-logo" />
      <Link className="Next-button--main" to="/first"><img src={nextbutton} alt="Следно" /></Link>
    </div>
  );
}

function First() {
  return (
    <div className="App-note--container">
      <Link className="Prev-button--main" to="/"><img src={nextbutton} alt="Претходно" /></Link>
      <img src={recenica} alt="Известување" class="App-izvestuvanje" />
      <Link className="Next-button--main" to="/second"><img src={nextbutton} alt="Следно" /></Link>

    </div>
  );
  
}

function Second() {


  const [firstElement, setFirstElement] = useState("row");
  const [secondElement, setSecondElement] = useState("row");
  const [click, addClick] = useState(0);


  function handleClick(e) {

    if(click == 0) {
      setFirstElement("row show-up");
      console.log(click);
    }

    if(click == 1) {
      setSecondElement("row show-up");

    }
    addClick(click + 1);
  }

  if(click > 2) {
    return (<Redirect to="/third" />);
  } else {
    return (
      <div className="App-1--container">
        <Link className="Prev-button--main" to="/first"><img src={nextbutton} alt="Претходно" /></Link>
  
        <div className="main_container">
  
          <div id="row1-1" className={firstElement}>
            <div className="col-left">
            <h1>На консументи на <strong>WEST BOX</strong></h1>
            <img className="element" src={row111} />
            </div>
            <div className="col-mid">
              <img src={arrow} class="arrow-mid" />
            </div>
            <div className="col-right">
            {/* <h1 class="flex-up"><span>Davidoff Reach</span> <span>West QS</span></h1> */}
            <img className="element" src={row112} />
            </div>
          </div>
  
          <div id="row1-2" className={secondElement}>
            <div className="col-left">
              <h1>На консументи на <strong>WEST SOFT</strong></h1>
              <img className="element" src={row121} />
            </div>
            <div className="col-mid">
              <img src={arrow} class="arrow-mid" />
            </div>
            <div className="col-right">
              {/* <h1 class="flex-up"><span>Rodeo Soft</span> <span class="spacer">Rodeo Compact</span> <span class="spacer-2">West Tutun</span></h1> */}
              <img className="element" src={row122} />
            </div>
          </div>
        </div>
  
        <a href="#" onClick={handleClick} className="Next-button--main"><img src={nextbutton} alt="Следно" /></a>
       
  
        {/* <Link className="Next-button--main" to="/third"><img src={nextbutton} alt="Следно" /></Link> */}
  
      </div>
    );
  }
  

  
}


function Third() {


  const [firstElement, setFirstElement] = useState("row");
  const [secondElement, setSecondElement] = useState("row");
  const [click, addClick] = useState(0);


  function handleClick(e) {

    if(click == 0) {
      setFirstElement("row show-up");
      console.log(click);
    }

    if(click == 1) {
      setSecondElement("row show-up");

    }
    addClick(click + 1);
  }

  if(click > 2) {
    return (<Redirect to="/fourth" />);
  } else {
    return (
      <div className="App-1--container">
        <Link className="Prev-button--main" to="/second"><img src={nextbutton} alt="Претходно" /></Link>
  
        <div className="main_container">
  
          <div id="row2-1" className={firstElement}>
            <div className="col-left">
            <h1>На консументи на <strong>WEST BOX</strong></h1>
            <img className="element" src={row211} />
            </div>
            <div className="col-mid">
              <img src={arrow} class="arrow-mid" />
            </div>
            <div className="col-right">
            {/* <h1 class="flex-up"><span>BOSS Soft</span> <span class="spacer-3">West Soft</span></h1> */}
            <img className="element" src={row212} />
            </div>
          </div>
  
          <div id="row2-2" className={secondElement}>
            <div className="col-left">
              {/* <h1>На консументи на <strong>BOSS Soft</strong></h1> */}
              <img className="element" src={row221} />
            </div>
            <div className="col-mid">
              <img src={arrow} class="arrow-mid" />
            </div>
            <div className="col-right">
              {/* <h1 class="flex-up"><span>Rodeo Soft</span> <span class="spacer">Rodeo Compact</span> <span class="spacer-2">West Tutun</span></h1> */}
              <img className="element" src={row222} />
            </div>
          </div>
        </div>
  
        <a href="#" onClick={handleClick} className="Next-button--main"><img src={nextbutton} alt="Следно" /></a>
      </div>
    );
  }

}


function Fourth() {

  const [firstElement, setFirstElement] = useState("row");
  const [secondElement, setSecondElement] = useState("row");
  const [click, addClick] = useState(0);


  function handleClick(e) {

    if(click == 0) {
      setFirstElement("row show-up");
      console.log(click);
    }

    if(click == 1) {
      setSecondElement("row show-up");

    }
    addClick(click + 1);
  }
  if(click > 2) {
    return (<Redirect to="/last" />);
  } else {
    return (
      <div className="App-1--container">
        <Link className="Prev-button--main" to="/third"><img src={nextbutton} alt="Претходно" /></Link>
  
        <div className="main_container">
  
          <div id="row3-1" className={firstElement}>
            <div className="col-left">
            <h1>На консументи на <strong>RODEO BOX</strong></h1>
            <img className="element" src={rodeobox} />
            </div>
            <div className="col-mid">
              <img src={arrow} class="arrow-mid" />
            </div>
            <div className="col-right">
            {/* <h1 class="flex-up"><span>Rodeo Soft</span> <span>Rodeo Compact</span> <span>West Tutun</span></h1> */}
            <img className="element" src={row222} />
            </div>
          </div>
  
          <div id="row3-2" className={secondElement}>
            <div className="col-left">
              <h1>На консументи на <strong>RODEO SOFT</strong></h1>
              <img className="element" src={rodeosoft} />
            </div>
            <div className="col-mid">
              <img src={arrow} class="arrow-mid" />
            </div>
            <div className="col-right">
              {/* <h1>West Tutun</h1> */}
              <img className="element" src={westtutun} />
            </div>
          </div>
        </div>
  
        <a href="#" onClick={handleClick} className="Next-button--main"><img src={nextbutton} alt="Следно" /></a>
      </div>
    );
  }

  
}


function Last() {
    return(
    <div className="App-last--container">
      <h1 class="end-hero">Ви благодариме за соработката.</h1>
      <Link className="Done-button--main" to="/"><img src={done} alt="Следно" /></Link>

    </div>
    );
}